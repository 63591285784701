.swiper-slide {
    transition: all 0.3s ease;
}

.swiper-slide-active {
}

.swiper-slide-next,
.swiper-slide-prev {
    background-color: #f6f6f8;
}

.bg-gradient-custom {
    background: linear-gradient(
            0deg,
            rgba(222, 222, 222, 0.3) 0%,
            rgba(222, 222, 222, 0.3) 100%
        ),
        #fff;
}

.slide-content {
    transition-delay: 0.3s;
    transition: opacity 1.5s ease !important;
}
.swiper-slide-prev .slide-content {
    opacity: 0;
}

.swiper-slide-next .slide-content {
    opacity: 0;
}

.swiper-slide-active .slide-content {
    opacity: 1;
}
