@font-face {
    font-family: "Manrope";
    src:
        local("Manrope-VariableFont_wght"),
        url("./fonts/Manrope/Manrope-VariableFont_wght.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --chart-medium-fill: #fa6f87;
    --chart-low-fill: #fc8e3f;
    --chart-high-fill: #d357fa;
    /* --gradient-medium: linear-gradient(to right, #fa6f87, #f256c0);
    --gradient-low: linear-gradient(to right, #fc8e3f, #fa6f87);
    --gradient-high: linear-gradient(to right, #d357fa, #9753fc); */
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;
        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;
        --radius: 0.5rem;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;
        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;
        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;
        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;
        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;
        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;
        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;
        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }

    html {
        height: 100%;
    }

    body {
        height: 100%;
        @apply font-manrope text-md text-black;
    }

    #root {
        height: 100%;
    }
}

.font-manrope {
    font-family: "Manrope", sans-serif;
}

/* -------------------------------- */
/* Disable arrow up/down for number input (globally) */
/* For Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
/* -------------------------------- */

/* -------------------------------- */
