/* LoadingSpinner.css */
.loadingSpinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingSpinner {
    border: 4px solid white;
    border-left-color: silver;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
